<template>
  <div v-if="isVisible" class="label-container" :style="{ 'background-color': computedBannerBackgroundColor, 'border-color': computedBannerBorderColor }">
    <div class="label-icon-container">
      <span
        v-if="computedIcon"
        class="icon-container"
        :style="{'color': computedIconColor}"
      >
        <fa-icon :icon="computedIcon" />
      </span>
      {{ computedStatus }}
    </div>
    <div class="label-text-container">
      <span class="label-text" v-html="message" />
    </div>
    <fa-icon v-if="canClose" class="close-btn" icon="times" size="sm" @click="closeBanner" />
  </div>
</template>

<script>
const BANNER_VARIANTS = {
  'failed': {
    'status': 'Failed',
    'icon': ['fas', 'times-circle'],
    'iconColor': '#CB2328',
    'bannerBackgroundColor': '#FFEBEE',
    'bannerBorderColor': '#CB2328',
  },
  'success': {
    'status': 'Success',
    'icon': ['fas', 'check-circle'],
    'iconColor': '#008F20',
    'bannerBackgroundColor': '#E4F8E9',
    'bannerBorderColor': '#008F20',
  },
  'warning': {
    'status': 'Warning',
    'icon': ['fas', 'exclamation-triangle'],
    'iconColor': '#FFC109',
    'bannerBackgroundColor': '#FFE8A4',
    'bannerBorderColor': '#FFC109',
  },
}

export default {
  name: 'Banner',
  props: {
    message: {
      type: String,
      default: null,
    },
    icon: {
      type: Array,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    bannerBackgroundColor: {
      type: String,
      default: null,
    },
    bannerBorderColor: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
    }
  },
  computed: {
    computedIcon() {
      return this.icon || BANNER_VARIANTS[this.variant]?.icon
    },
    computedIconColor() {
      return this.iconColor || BANNER_VARIANTS[this.variant]?.iconColor
    },
    computedBannerBackgroundColor() {
      return this.bannerBackgroundColor || BANNER_VARIANTS[this.variant]?.bannerBackgroundColor
    },
    computedBannerBorderColor() {
      return this.bannerBorderColor || BANNER_VARIANTS[this.variant]?.bannerBorderColor
    },
    computedStatus() {
      return this.status || BANNER_VARIANTS[this.variant]?.status
    },
  },
  methods: {
    closeBanner() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped lang="scss">

.label-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #FFF;
  border: 1px solid;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0;

  .label-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0.5rem;
    height: 100%;
  }

  .label-text-container {
    display: flex;
    align-items: center;
    width: 100%;

    .label-text {
      color: #363636;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .close-btn {
    cursor: pointer;
    padding-right: 1rem;
  }
}
</style>

