var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "label-container",
          style: {
            "background-color": _vm.computedBannerBackgroundColor,
            "border-color": _vm.computedBannerBorderColor,
          },
        },
        [
          _c("div", { staticClass: "label-icon-container" }, [
            _vm.computedIcon
              ? _c(
                  "span",
                  {
                    staticClass: "icon-container",
                    style: { color: _vm.computedIconColor },
                  },
                  [_c("fa-icon", { attrs: { icon: _vm.computedIcon } })],
                  1
                )
              : _vm._e(),
            _vm._v("\n    " + _vm._s(_vm.computedStatus) + "\n  "),
          ]),
          _c("div", { staticClass: "label-text-container" }, [
            _c("span", {
              staticClass: "label-text",
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
          ]),
          _vm.canClose
            ? _c("fa-icon", {
                staticClass: "close-btn",
                attrs: { icon: "times", size: "sm" },
                on: { click: _vm.closeBanner },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }